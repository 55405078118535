// Lumen 4.6.0
// Bootswatch


// Variables ===================================================================

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Regular.woff') format('woff');
  font-weight: regular;
}

@font-face {
  font-family: 'Lato';
  src: url('fonts/Lato-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'Domine';
  src: url('fonts/Domine-Regular.woff') format('woff');
  font-weight: regular;
}

@font-face {
  font-family: 'Domine';
  src: url('fonts/Domine-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'Segoeui';
  src: url('fonts/Segoeui-Regular.woff') format('woff');
  font-weight: regular;
}

@font-face {
  font-family: 'Segoeui';
  src: url('fonts/Segoeui-Bold.woff') format('woff');
  font-weight: bold;
}

@font-face {
  font-family: 'Franklin Gothic Book';
  src: url('fonts/Franklin Gothic Book.woff2') format('woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'Franklin Gothic Demi';
  src: url('fonts/Franklin Gothic Demi.woff2') format('woff2');
  font-weight: bold;
}


// Mixins ======================================================================

@mixin shadow() {
  box-shadow: $box-shadow !important;
}

// Custom Additions ============================================================
.panel-heading, .modal-header {
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Segoeui', Fallback, sans-serif;
    color: $primary;
  }
}


// Navbar ======================================================================


.bg-primary {
  border-color: darken($primary, 5%);
}

.bg-dark {
  border-color: darken($dark, 5%);
}

.bg-light {
  background-color: $white !important;
  border-color: darken($white, 5%);
}

.navbar {
  border-bottom-left-radius: 70px !important;
  border-bottom-right-radius: 70px !important;
  box-shadow: 0px 3px 6px rgb(32 32 32 / 30%);
  height: 64px;
}

.navbar-light .navbar-brand {
  border-right: 1px solid $primary;
}
// Buttons =====================================================================

.btn {
  text-transform: uppercase;
  // min-width: 110px; requiered after layout change

  &:not(.disabled):active {
    margin-top: 2px;
  }

  &-primary {
    border: 1px solid $primary;
    background: $primary;

    &:hover {
      border: 1px solid $primary;
      background: $primary;
    }

    &:disabled {
      color: $white;
      background-color: $disabled;
      border-color: $disabled;
    }
  }

  &-outline-primary {

    &:hover {
      background-color: $primary-30;
      color: $primary;
    }

    &:disabled {
      color: $disabled;
      border-color: $disabled;
    }
  }

  &-text {
    color: $primary;

    &:hover {
      background-color: $primary-30;
      color: $primary;
    }

    &:disabled {
      color: $disabled;
    }
  }

  &-secondary {
    border-color: darken($secondary, 5%);
  }

  &-success {
    border-color: darken($success, 5%);
  }

  &-info {
    border-color: darken($info, 5%);
  }

  &-danger {
    border-color: darken($danger, 5%);
  }

  &-warning {
    border-color: darken($warning, 5%);
  }

  &-light {
    border-color: darken($light, 5%);
  }

  &-dark {
    border-color: darken($dark, 5%);
  }
}

[class*="btn-outline"] {
  border-top-width: 1px;
}

.btn-group-vertical {
  .btn + .btn {
    &:hover {
      margin-top: -1px;
      border-top-width: 1px;
    }

    &:active {
      margin-top: -1px;
      border-top-width: 2px;
    }
  }
}

.btn-lg, .btn-group-lg > .btn {
  font-size: 1.01rem;
}

// Typography ==================================================================

.text-secondary {
  color: $gray-700 !important;
}

.text-info {
  color: $primary-400 !important;
}

.blockquote-footer {
  color: $gray-600;
}

// Tables ======================================================================

.table {
  border-collapse: separate;
  border-spacing: 0 0.3rem;

  &-primary,
  &-success,
  &-info,
  &-warning,
  &-danger {
    color: $white;
  }

  &-hover tbody {
    .table-primary:hover,
    .table-success:hover,
    .table-info:hover,
    .table-warning:hover,
    .table-danger:hover,
    .table-dark:hover {
      color: $white;
    }
  }

  &-primary {
    &,
    > th,
    > td {
      background-color: $primary;
    }
  }

  &-secondary {
    &,
    > th,
    > td {
      background-color: $secondary;
    }
  }

  &-light {
    &,
    > th,
    > td {
      background-color: $light;
    }
  }

  &-dark {
    &,
    > th,
    > td {
      background-color: $dark;
    }
  }

  &-success {
    &,
    > th,
    > td {
      background-color: $success;
    }
  }

  &-info {
    &,
    > th,
    > td {
      background-color: $info;
    }
  }

  &-danger {
    &,
    > th,
    > td {
      background-color: $danger;
    }
  }

  &-warning {
    &,
    > th,
    > td {
      background-color: $warning;
    }
  }

  &-active {
    &,
    > th,
    > td {
      background-color: $table-active-bg;
    }
  }

  &-hover {
    .table-primary:hover {
      &,
      > th,
      > td {
        background-color: darken($primary, 5%);
      }
    }

    .table-secondary:hover {
      &,
      > th,
      > td {
        background-color: darken($secondary, 5%);
      }
    }

    .table-light:hover {
      &,
      > th,
      > td {
        background-color: darken($light, 5%);
      }
    }

    .table-dark:hover {
      &,
      > th,
      > td {
        background-color: darken($dark, 5%);
      }
    }

    .table-success:hover {
      &,
      > th,
      > td {
        background-color: darken($success, 5%);
      }
    }

    .table-info:hover {
      &,
      > th,
      > td {
        background-color: darken($info, 5%);
      }
    }

    .table-danger:hover {
      &,
      > th,
      > td {
        background-color: darken($danger, 5%);
      }
    }

    .table-warning:hover {
      &,
      > th,
      > td {
        background-color: darken($warning, 5%);
      }
    }

    .table-active:hover {
      &,
      > th,
      > td {
        background-color: $table-active-bg;
      }
    }

  }
}

.table td {
  border: none;
  background-color: $gray-110;
  color: $text-secondary;
  font-weight: 400;
  font-size: 16px;
}

.table thead th {
  border: none;
  color: $secondary-color-grey;
  font-weight: 600;
  padding: 0.3rem 0.7rem;
}

.table th, .table td {
  border: none;
  vertical-align: middle;
}
// Forms =======================================================================

.input-group-sm {
  > .input-group-prepend,
  > .input-group-append {
    .btn {
      font-size: $btn-font-size-sm;
    }
  }
}

.form-control:focus {
  color: $black !important;
  border: 2px solid $primary-200 !important;
  box-shadow: none !important;
}

.form-check-input {
  height: 22px;
  width: 22px;
  accent-color: $primary;
}

.form-check-label {
  margin-left: 5px;
  padding-top: 5px;
}

.form-control {
  background-color: $white;
  border: 1px solid $primary-200;
  color: $black;
}

.react-datepicker {
  border: 1px solid $primary;

  &__triangle {
    display: none;
  }

  &__day--selected {
    background-color: $primary;
    border-radius: 5px !important;
  }

  &__header {
    background-color: $white;
    border: none;
  }

  &__header:before {
    content : "";
    position: absolute;
    left    : 8px;
    bottom  : 0;
    height  : 1px;
    width   : 94%;
    border-bottom:1px solid $primary;
  }

  &__day {
    margin: 0.5rem;
  }

  &__current-month {
    color: $primary;
    font-weight: 600;
    font-size: 16px;
  }

  &__icon {
    color: $primary-300;
    position: absolute;
    bottom: 0.7em;
    right: 5%;
  }
}

// Navs ========================================================================

.nav {
  .open > a,
  .open > a:hover,
  .open > a:focus {
    border-color: transparent;
  }
}

.nav-tabs {
  .nav-link {
    padding-left: 2rem;
    padding-right: 2rem;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    background-color: transparent;
    border-width: 0 0 3px;
    border: 0px solid transparent;
    transition: none;
    position: relative;
    padding: 0px 15px 10px 15px;
    display: block;

    &,
    &.disabled,
    &.disabled:hover,
    &.disabled:focus {
      margin-top: 6px;
      border-color: $nav-tabs-border-color;
      transition: padding-bottom .2s ease-in-out, margin-top .2s ease-in-out, border-bottom .2s ease-in-out;
    }

    &:not(.disabled):hover,
    &:not(.disabled):focus,
    &.active {
      border-width: 0 0 3px;
      background-color: transparent;
    }

    &.active {
      border-color: $primary;
    }

    &:not(.disabled):not(.active):hover,
    &:not(.disabled):not(.active):focus {
      border-color: $gray-500;
    }

    &.active:after {
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -10px;
      width: 0;
      height: 0;
      border: solid transparent;
      border-width: 10px;
      border-top-color: $primary;
      content: '';
      pointer-events: none;
    }
  }

  &.nav-justified > li {
    vertical-align: bottom;
  }
}

.dropdown-menu {
  margin-top: 0;
  border-top-width: 1px;
  @include box-shadow(none);
}

.breadcrumb {
  background-color: $white;
  margin: 0;
  font-size: 1.1em;
}

.readcrumb-item, .breadcrumb-item:not(:first-child):before {
  color: $primary;
  content: ">" !important;
}

.breadcrumb-item.active {
  color: $primary;
}

.pagination {
  > li > a,
  > li > span {
    position: relative;
    top: 0;
    @include shadow();
    color: $pagination-color;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;

    &:hover,
    &:focus {
      top: 1px;
      border-bottom-width: 3px;
      text-decoration: none;
    }

    &:active {
      top: 2px;
      border-bottom-width: 2px;
    }
  }

  > .disabled > a,
  > .disabled > span {
    &:hover {
      top: 0;
      @include shadow();
    }

    &:active {
      top: 0;
      @include shadow();
    }
  }
}

.pager {
  > li > a,
  > li > span,
  > .disabled > a,
  > .disabled > span {
    &,
    &:hover,
    &:active {
      border-left-width: 2px;
      border-right-width: 2px;
    }
  }
}

// Indicators ==================================================================

.close {
  text-decoration: none;
  opacity: .4;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

.alert {
  color: $gray-700;
  @include shadow();

  &-primary {
    background-color: $primary;
    border-color: darken($primary, 5%);
  }

  &-secondary {
    background-color: $secondary;
    border-color: darken($secondary, 5%);
  }

  &-success {
    background-color: $success;
    border-color: darken($success, 5%);
  }

  &-info {
    background-color: $info;
    border-color: darken($info, 5%);
  }

  &-danger {
    background-color: $danger;
    border-color: darken($danger, 5%);
  }

  &-warning {
    background-color: $warning;
    border-color: darken($warning, 5%);
  }

  &-dark {
    background-color: $dark;
    border-color: darken($dark, 5%);
  }

  &-light {
    background-color: $light;
    border-color: darken($light, 5%);
  }

  .alert-link {
    font-weight: 400;
    color: $white;
    text-decoration: underline;
  }

  &-secondary,
  &-light {
    &,
    a,
    .alert-link {
      color: $body-color;
    }
  }
}

.badge {
  padding: 7px 8px 7px 8px;
  font-weight: 400;
  font-size: 16px;
  
  &-warning,
  &-info {
    color: $white;
  }
}

// Containers ==================================================================

a.list-group-item {
  &-success {
    &.active {
      background-color: $success;
    }

    &.active:hover,
    &.active:focus {
      background-color: darken($success, 5%);
    }
  }

  &-warning {
    &.active {
      background-color: $warning;
    }

    &.active:hover,
    &.active:focus {
      background-color: darken($warning, 5%);
    }
  }

  &-danger {
    &.active {
      background-color: $danger;
    }

    &.active:hover,
    &.active:focus {
      background-color: darken($danger, 5%);
    }
  }
}

.jumbotron {
  border: 1px solid $gray-200;
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, .05);
}

// Toast Customizations ====================================================================

.modal,
.toast {
  .close {
    color: $black;

    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):focus {
      color: $black;
    }
  }
}

.toast-header {
  color: $black;
  background-color: transparent;
  height: 50px;
}

.toast {
  max-width: 98vw;
  width: 98vw;
}

.modal-header {
  background: url("../../assets/images/ayming-logo-without-text.svg") right 115%/22% no-repeat, linear-gradient(90deg, #0078B9 10%, #00aeef 85%);
  width: 100%;
  height: 84px;
  overflow: hidden;
  color: white;
  display: flex;
  align-items: center;
}

.modal-title {
  display: contents;
}

.modal-footer {
  border: none;
}

.modal-dialog {
  margin-top: 10%;
}

// Navbar Customizations ====================================================================

.navbar {
  background-color: $white;
  padding: 0px;
  margin-bottom: 20px;
  z-index: 4;
}

.navbar-light .navbar-nav .nav-link {
    color: $primary;
    &.active {
      background-color: $secondary;
      color: $primary;
    }
}

.navbar-light .navbar-nav .show > .nav-link {
  color: $primary;
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: $primary;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: $primary;
  text-decoration: none;
  background: #f0f0f0 linear-gradient(180deg, #f2f2f2, #f0f0f0) repeat-x;
}

.navbar-brand { /* change padding for 30px logo */
    padding: 2px 15px;
}

.nav-item {
    font-size: 1.1em;
}

.nav-tabs .nav-link.active:after {
  display: none;
}

// Card Customizations =====================================================================

.card {
  @include shadow();
}
.card-footer {
  background-color: $white;
}

.card-header {
  @extend .rounded-top;
  background-color: $white;
  font-size: 1rem;
}

// make disabled buttons not hover
.disabled:hover,
.disabled:focus {
  @extend .disabled;
}

// make disabled buttons not hover
.disabled:hover,
.disabled:focus {
  @extend .disabled;
}

// do not make tabs large like a header
.card-header > .nav {
  font-size: 0.85rem;
}

// Borders ===================================================================================

.border-primary {
  border-color: $primary !important;
}

.border-secondary {
  border-color: $secondary !important;
}

.border-light {
  border-color: $primary-200 !important;
}

// Popovers ==================================================================================

#teaching-bubble-container {
  .popover-header {
    background: linear-gradient(90deg, #0078b9 10%, #00aeef 85%);
    box-shadow: 0px 3px 6px rgba(0, 120, 185, 0.3);
    border-bottom: none;
    color: $white;
  }
  
  .popover-body {
    background: linear-gradient(90deg, #0078b9 10%, #00aeef 85%);
    box-shadow: 0px 3px 6px rgba(0, 120, 185, 0.3);
    color: $white;
    font-size: 0.9rem;
  }
  
  .bs-popover-bottom > .arrow::after {
    border-bottom-color: #0094d5;
  }
  
  .bs-popover-bottom .popover-header::before {
    border-bottom: none;
  }
  
  .bs-popover-left > .arrow::after {
    border-left-color: #0094d5;
  }
  
  .bs-popover-left .popover-header::before {
    border-left: none;
  }
  
  .bs-popover-right > .arrow::after {
    border-right-color: #0094d5;
  }
  
  .bs-popover-right .popover-header::before {
    border-right: none;
  }
  
  .bs-popover-top > .arrow::after {
    border-top-color: #0094d5;
  }
  
  .bs-popover-top .popover-header::before {
    border-top: none;
  }
}
