tr.isDraggingOver td,
span.isDraggingOver {
    background-color: #7a9eb2;
}

.folder-path {
    opacity: 0.5;
    color: rgb(0, 120, 185);
    transition: opacity 0.1s ease-in-out;
}

.folder-path:hover {
    opacity: 0.8;
}
