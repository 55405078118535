@import "./colors.scss";

.Dashboard {
  font-family: 'Franklin Gothic Book', sans-serif;
}

.dashboard-card-title {
  font-family:georgia, serif;
  font-size: 1.5rem;
  color: $color-text-primary;
}

.dashboard-card-title-small {
  font-size: 1.2rem;
}

.dashboard-card-blue {
  background-color: #00aeef;;
  color: white;
}

.dashboard-card {
  color: $color-gray;
}

.dashboard-business-unit-selector-card {
  border: 1px solid $color-primary;
  color: $color-gray;
 }

 .dashboard-business-unit-multiple-select {
    cursor: pointer;
    &__active {
      color: $color-primary;
    }

    &__inactive {
      color: $color-gray;
    }
 }

.busines-unit-item {
   background-color: #c0c0c0;
}

.dashboard-regular-header {
  background: rgb(0,87,119);
  background: linear-gradient(90deg, rgba(0,87,119,1) 0%, rgba(0,120,185,1) 50%, rgba(0,87,119,1) 100%);
  color: $white;
  border-radius: 8px;
  padding: 0.5rem;
  margin-bottom: 12px;
}

.dashboard-regular-card-title {
  font-size: 1.5rem;
  color: $color-gray;
}