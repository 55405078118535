// Ayming theme - based on Lumen 4.6.0

/*
+
+ayming colors:
+
+#0093E6 - light blue
+#1362B9 - dark blue - used as brand primary
+#79008E - dark purple - used as brand info
+#9C007A - light purple
+#B30037 - tile red
+#00B584 - green
+#6C6C81 - blue grey dark
+#7AD404 - light green
+#E1B800 - yellow
+#E7291D - regular red 0 used as brand danger
+
+titles - domaine bold
+subheading - domaine bold
+body heading - lato bold
+body copy - lato reguler
+*/


// Bootswatch

//
// Color system
//

// TODO: reimplement the following additions:in a bootstrappy way:
// $panel-bg:               #f5f5f5 !default;
// $gray-base:              #000000 !default;
// $gray-lightest:          darken($panel-bg, 15%) !default; // #???
// $component-active-color: $brand-primary default;
// $text-color:            $gray-base !default;
// $headings-color:          $gray-base !default;
// $input-border:                   $gray-light  !default; //#727269
// legend-color:                   $gray-base !default;
// $dropdown-border:                transparent !default;
// dropdown-link-hover-color:      lighten($brand-primary, 10%) !default;
// $dropdown-link-hover-bg:         $component-active-bg !default;
// $navbar-border-radius:             0px !default;
// navbar-default-bg:                #f5f5f5 !default;
// navbar-default-border:            transparent !default;
// $navbar-default-link-color:                $brand-primary !default;
// $navbar-default-link-hover-color:          darken($brand-primary, 5%) !default;
// $navbar-default-link-hover-bg:             $gray-lightest !default;
// $navbar-default-link-active-bg:            $navbar-default-link-hover-bg !default;
// $navbar-inverse-link-hover-color:           $gray-base !default;
// $nav-tabs-active-link-hover-bg:             $navbar-default-link-active-bg !default;
// $nav-tabs-active-link-hover-color:          $navbar-default-color !default;
// $panel-footer-bg:             transparent !default;
// $panel-default-text:          $gray-base !default;
// $pre-color:                   $gray-base !default;
// $hr-border:                   $gray-light !default;


$white:    #fff !default;
$gray-100: #f6f6f6 !default;
$gray-110: #f2f2f3 !default;
$gray-150: #e8e9ec !default;
$gray-200: #f0f0f0 !default;
$gray-300: #eee !default;
$gray-400: #d9d9d9 !default;
$gray-500: #c4c4c4 !default;
$gray-600: #999 !default;
$gray-700: #555 !default;
$gray-800: #333 !default;
$gray-900: #222 !default;
$black:    #000 !default;
$background: #f9f9f9 !default;

$primary-30: #eff4f6 !default;
$primary-200: #80bbdc !default;
$primary-300: #7fbbdc !default;
$primary-400: #00aeef !default;

$primary-30: #eff4f6 !default;
$primary-200: #80bbdc !default;
$primary-300: #7fbbdc !default;
$primary-400: #00aeef !default;

$blue:    #0078B9 !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #ff4136 !default;
$orange:  #f06723 !default;
$yellow:  #ff851b !default;
$green:   #00B08B !default;
$teal:    #00aeef !default;
$cyan:    #75caeb !default;
$success-color: #8dc63f !default;
$info-clor: #e6f1f8 !default;
$warning-color: #dca90e !default;
$error: #dc533a !default;
$disabled: #b2b6be !default;
$important: #684198!default;
$scrollbar-color-grey: #8B8B8B !default;
$secondary-color-grey: #666e7e !default;
$text-primary: #202020 !default;
$text-secondary: #393939 !default;

$primary:       $blue !default;
$secondary:     $gray-200 !default;
$success:       $success-color !default;
$info:          $info-clor !default;
$warning:       $warning-color !default;
$danger:        $error !default;
$light:         $gray-100 !default;
$dark:          $gray-700 !default;

$yiq-contrasted-threshold: 200 !default;



// Fonts

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif:      "Segoeui", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-size-base:              .875rem !default;

// Buttons

$btn-font-size:               .75rem !default;
$btn-font-size-sm:            .625rem !default;
$btn-font-weight:             normal !default;

// Dropdowns

$dropdown-link-color:         $primary !default;

// Navs

$nav-tabs-border-color:             $gray-200 !default;
$nav-tabs-link-hover-border-color:  $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $primary !default;
$nav-tabs-link-active-border-color: $nav-tabs-border-color !default;

// Pagination

$pagination-color:                  $gray-700 !default;
$pagination-bg:                     $gray-200 !default;
$pagination-hover-color:            $pagination-color !default;
$pagination-hover-bg:               $pagination-bg !default;
$pagination-active-border-color:    darken($primary, 5%) !default;
$pagination-disabled-color:         $gray-600 !default;
$pagination-disabled-bg:            $pagination-bg !default;

// Jumbotron

$jumbotron-bg:                      #fafafa !default;

// Modals

$modal-content-border-color:        rgba($black, .1) !default;

// Close

$close-color:                       $white !default;
