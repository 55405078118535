$color-primary: #0078b9;
$color-primary-light: #00aeef;
$color-primary-lighter: #80bbdc;

$color-text-primary: #202020;
$color-text-secondary: #393939;

$color-page-background: #f9f9f9;

$color-disabled: #393939;

$color-primary-gradient-start: #0078b9;
$color-primary-gradient-end: #00aeef;

$color-success: #8dc63f;
$color-warning: #dca90e;
$color-error: #dc533a;
$color-info: #e6f1f8;

$color-gray: #666e7e;
$color-green: #00b08b;
$color-red: #a20a42;
$color-violet: #8b2885;
$color-purple: #684198;
$color-white: white; // for completeness
