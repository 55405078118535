.td-dashboard td {
    font-size: 12px !important;
    background-color: transparent !important;
    border-top: 0.5px solid rgba(79, 79, 79, 0.191);
}

.completed {
    background-color: rgba(0, 176, 139) !important;
    border-radius: 50rem!important;
}
