.tox-tinymce {
  border: 1px solid $primary-200 !important;
  border-radius: 0.25rem !important;
}

.tox .tox-menubar+.tox-toolbar, .tox .tox-menubar+.tox-toolbar-overlord .tox-toolbar__primary {
  border-top: 1px solid $primary-200 !important;
  border-bottom: 1px solid $primary-200 !important;
  background: none;
}

.tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type) {
  border-right: 1px solid $primary-200 !important;
}

.tox .tox-statusbar {
  border-top: 1px solid $primary-200 !important;
}